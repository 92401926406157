.settings-page-container {
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px);
}

.settings-page-header {
  background-color: $white;
}

.settings-page-body {
  padding: 32px;
  overflow-y: auto;
  scrollbar-width: thin;
  flex-grow: 1;

  h3 {
    line-height: 1;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.settings-page-section {
  background-color: $white;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid $gray-200;
}

.settings-page-footer {
  background-color: $white;
  border-top: 1px solid $gray-200;
}
